import '../../index.css';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * Choice Component represents an option item that can be selected.
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.ico - The icon file name for the choice.
 * @param {string} props.label - The label text for the choice.
 * @param {any} props.value - The value associated with the choice.
 * @param {boolean} props.active - Whether the choice is active or not.
 * @param {Function} props.cbOnOptionClick - The callback function triggered
 * when the choice is selected.
 * @returns {JSX.Element} - The rendered Choice components.
 */
function HEYGChoiceBox({
  ico, label, value, active, cbOnOptionClick,
}) {
  const onOptionClick = () => {
    // Triggers the `onSelectOption` callback after a delay of 250ms.
    setTimeout(() => {
      cbOnOptionClick(value);
    }, 250);
  };

  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <div>
      <button type="button" className="heyg-choice-box h-full" onClick={onOptionClick}>
        <div
          className="h-full min-h-[60px] flex justify-start items-center gap-4 text-truncate pl-4 pr-6 py-[18px] shadow-sm cursor-pointer"
        >
          {ico && <img src={`images/icons/${ico}`} width="32px" height="32px" alt={label} />}
          <div className="flex-1 text-left select-none">{label}</div>
          {active && (
          <span
            className="flex h-6 w-6 items-center justify-center rounded-full bg-HEYG-primary sm:mr-2"
          >
            <img src='images/icons/check-mark.svg' width="12px" height="12px" alt="Checkmark Icon" />
          </span>
          )}
        </div>
      </button>
    </div>
  );
}

HEYGChoiceBox.propTypes = {
  ico: PropTypes.string,
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  active: PropTypes.bool,
  cbOnOptionClick: PropTypes.func.isRequired,
};

HEYGChoiceBox.defaultProps = {
  ico: null,
  active: false,
};

export default HEYGChoiceBox;
