import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  redirectToConfirmation,
  redirectToStart,
} from "../../../../@global/redirection";
import { decodeFromUrlQueryString } from "../../../../@global/encoder";

import PageLayout2 from "../../../../@ui-common/PageLayout2/PageLayout2";

import ConfiguratorChoices from "./configurator-choices/configurator-choices";
import ConfiguratorBenefitDisplay from "./configurator-benefit-display/configurator-benefit-display";
import { postFilledConfig$ } from "../../services/configurator-service";
import {
  createDealObject,
  createContactObject,
} from "../../services/lead-service";
import {
  HEY_GRUEN_CHOICE_STEP,
  SELFMADE_CHOICE_STEP,
} from "../../data/choice_step";
import ImplementationChoice from "./implementation-choice";

function PageConfigurator({ process, callback }) {
  const [leadRoofSize, setLeadRoofSize] = useState(0);
  const [isAvailable, setIsAvailable] = useState(false);
  const [implementationType, setImplementationType] = useState("");
  const [steps, setSteps] = useState([]);

  /**
   * Retrieves the lead roof area from the URL query parameters.
   * If the roof area is not available, it redirects to the landing page.
   * @returns {string} The lead roof area
   */
  const retrieveLeadServiceArea = () => {
    const queryString = window.location.search;
    const decodedUrlParams = decodeFromUrlQueryString(queryString);
    const leadRoofArea = parseInt(decodedUrlParams.get("roof-size"));
    const availability = decodedUrlParams.get("available");

    if (
      leadRoofArea == null ||
      leadRoofArea === "" ||
      availability == null ||
      availability === ""
    ) {
      redirectToStart();
      return;
    }

    return { leadRoofArea, availability };
  };

  /**
   * Handles the form submission of the ConfiguratorChoices components
   * @param {object} cfg - Configuration data from the ConfiguratorChoices components
   * @param {object} submitConfig - Form data from the ConfiguratorChoices components
   */
  const onSubmitSteps = (cfg, submitConfig) => {
    callback({ isPending: true, submitted: false, hasError: false });

    const submission = {
      lead_deal_obj: createDealObject(cfg, leadRoofSize, submitConfig, implementationType),
      lead_contact_obj: createContactObject(submitConfig),
    };

    postFilledConfig$(submission).subscribe(async (res) => {
      callback({
        isPending: false,
        submitted: true,
        hasError: res.error || !res.ok,
      });

      const response = await res.json();

      if (!res.error || res.ok) {
        redirectToConfirmation(
          submitConfig.lead_first_name,
          submitConfig.lead_second_name,
          submitConfig.lead_mail,
          response.rows.deal.id,
          implementationType
        );
      }
    });
  };

  useEffect(() => {
    const { leadRoofArea, availability } = retrieveLeadServiceArea();
    setLeadRoofSize(leadRoofArea);
    setIsAvailable(availability === "true");
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <PageLayout2 banner={<ConfiguratorBenefitDisplay />}>
      {steps.length === 0 ? (
        <ImplementationChoice available={isAvailable} callback={(value) => {
          switch (value) {
            case 'selfmade':
              setSteps(SELFMADE_CHOICE_STEP)
              break;
            default:
              setSteps(HEY_GRUEN_CHOICE_STEP)
          }

          setImplementationType(value)
        }} />
      ) : (
        <ConfiguratorChoices
          processError={process.hasError}
          onSubmitCfg={onSubmitSteps}
          steps={steps}
          roofSize={leadRoofSize}
          implementationType={implementationType}
        />
      )}
    </PageLayout2>
  );
}

PageConfigurator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  process: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};

export default PageConfigurator;
