import { REST_NEW_LEAD, AVAILABLITY_CHECK, PRICE_ORIENTATION } from '../../../@global/routes';
import restCallBuilderService from '../../../@global/rest-call-builder/rest-call-builder.service';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
function servicePostFilledConfig$(config = null) {
  return restCallBuilderService({
    requestRoute: REST_NEW_LEAD,
    init: {
      method: 'POST',
      body: JSON.stringify(config),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

function serviceGetPostalCodeAvailablity$(postalCode) {
  return restCallBuilderService({
    requestRoute: AVAILABLITY_CHECK + "?postalCode=" + postalCode,
    init: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

function serviceGetPricingOrientation$(roofSize, implementationType) {
  return restCallBuilderService({
    requestRoute: PRICE_ORIENTATION + "?roofSize=" + roofSize + "&implementationType=" + implementationType,
    init: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}


export { servicePostFilledConfig$, serviceGetPostalCodeAvailablity$, serviceGetPricingOrientation$ };
