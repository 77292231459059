import { INTERN_CONFIGURATOR_START } from './routes';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
const redirectToStart = () => {
  window.location.href = `${INTERN_CONFIGURATOR_START}`;
};

const redirectToConfirmation = (firstName, lastName, email, dealId, implementationType) => {
  const params = new URLSearchParams({
    name: `${firstName} ${lastName}`,
    email: email,
    utm_term: dealId
  });

  if (implementationType === "selfmade") {
    window.location.href = `https://calendly.com/heygruen/beratungsgesprach-diy?${params.toString()}`;
  } else {
    window.location.href = `https://calendly.com/heygruen/beratungsgespraech?${params.toString()}`;
  }
}

export { redirectToStart, redirectToConfirmation };
