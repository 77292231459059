import React from 'react';

function Loading() {
    return (
        <div className={"p-10 w-full flex items-center justify-center"}>
            <span className={"loader"}></span>
        </div>
    );
}

export default Loading;