import {
  catchError, of, switchMap, take,
} from 'rxjs';

import {servicePostFilledConfig$, serviceGetPostalCodeAvailablity$, serviceGetPricingOrientation$} from '../rest-services/configurator-rest-service';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
function postFilledConfig$(config = null) {
  try {
    return servicePostFilledConfig$(config).pipe(
      take(1),
      switchMap((response) => of(response)),
      catchError((err) => of({ status: 500, error: true, message: err })),
    );
  } catch (e) {
    return of({ status: 500, error: true, message: e.message });
  }
}

function postalCodeAvailablity$(postalCode) {
  try {
    return serviceGetPostalCodeAvailablity$(postalCode).pipe(
      take(1),
      switchMap((response) => of(response)),
      catchError((err) => of({ status: 500, error: true, message: err })),
    );
  } catch (e) {
    return of({ status: 500, error: true, message: e.message });
  }
}

function pricingOrientation$(roofSize, implementationType) {
  try {
    return serviceGetPricingOrientation$(roofSize, implementationType).pipe(
      take(1),
      switchMap((response) => of(response)),
      catchError((err) => of({ status: 500, error: true, message: err })),
    );
  } catch (e) {
    return of({ status: 500, error: true, message: e.message });
  }
}

export { postFilledConfig$, postalCodeAvailablity$, pricingOrientation$ };
