import React from "react";
import { INTERN_CONFIGURATOR_PLANER } from "../../../@global/routes";
import { encodeBase64 } from "../../../@global/encoder";
import { postalCodeAvailablity$ } from '../services/configurator-service';

import HEYGText from "../../../@ui-common/HEYGText/HEYGText";
import SectionM from "../../../@ui-common/SectionM/SectionM";

import AverageRoofSizes from "../enums/average-roof-sizes";
import PageLayout1 from "../../../@ui-common/PageLayout1/PageLayout1";

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * PageConfiguratorLanding components represents the landing page for the application.
 * Users can input roof size and proceed with planning.
 */
function PageConfiguratorLanding() {
  const renderRoofSizeForm = () => {
    /**
     * Handles the form submission for roof size.
     * If the input is valid, redirects to the planer page with the roof size parameter.
     */
    const handleRoofSizeSubmit = async () => {
      const inputErrorSpan = document.querySelector(
        '[id="error-submit-roof_size"]'
      );
      const leadRoofSizeInput = document.querySelector(
        '[id="submit-roof_size"]'
      );
      const leadPostalCodeInput = document.querySelector(
        '[id="submit-postalcode"]'
      );
      const inputErrorSpanPostalCode = document.querySelector(
        '[id="error-submit-postalcode"]'
      );
      const validation = /^[0-9]{1,}$/;
      const dangerHex = "#FF6B60";

      if (
        leadRoofSizeInput.value.match(validation) == null ||
        leadRoofSizeInput.value === ""
      ) {
        inputErrorSpan.textContent =
          "Bitte gib eine gültige Dachfläche in m² ein";
        leadRoofSizeInput.style.borderColor = dangerHex;
        return;
      }

      // check if valid postal code
      if (leadPostalCodeInput.value.match(/^[0-9]{5}$/) == null) {
        inputErrorSpanPostalCode.textContent = "Bitte gib eine gültige Postleitzahl ein";
        leadPostalCodeInput.style.borderColor = dangerHex;
        return;
      }

      if (leadRoofSizeInput.value < 7) {
        inputErrorSpan.textContent =
          "Die Dachfläche sollte größer als 7m² sein";
        leadRoofSizeInput.style.borderColor = dangerHex;
        return;
      }

      inputErrorSpan.textContent = " ";
      inputErrorSpanPostalCode.textContent = " ";
      leadRoofSizeInput.style.borderColor = "#e5e7eb";

      // fetch postal code availablity via rest
      postalCodeAvailablity$(leadPostalCodeInput.value).subscribe((res) => {
        const { status } = res;

        window.location.href = `${INTERN_CONFIGURATOR_PLANER}?${encodeBase64(
          `roof-size=${leadRoofSizeInput.value}&postalcode=${leadPostalCodeInput.value}&available=${status === 200}`
        )}`;
      });
    };

    return (
      <div>
        <SectionM classname="heyg-container--corporate">
          <div>
            <div className="flex flex-col sm:flex-row items-end justify-between gap-4 relative">
              <HEYGText
                id="submit-roof_size"
                type="number"
                name="roof-size"
                label="Zu begrünende Dachfläche in m² (geschätzt)"
                required
                placeholder="Bitte eingeben"
              />
              <HEYGText
                id="submit-postalcode"
                type="number"
                name="postalcode"
                label="Postleitzahl"
                required
                placeholder="Bitte eingeben"
              />
            </div>
            <div className="flex flex-center p-4">
              <button
                type="button"
                onClick={handleRoofSizeSubmit}
                className="cta-button w-full md:w-2/4 mt-8"
              >
                Jetzt planen »
              </button>
            </div>
            <span className="heyg-text-base block mt-4 box-decoration-clone text-center">
              <strong>Gut zu Wissen:</strong> Durchschnittliche
              Orientierungswerte: {AverageRoofSizes.singleGarage} m²
              Einzelgarage, {AverageRoofSizes.doubleGarage} m² Doppelgarage,{" "}
              {AverageRoofSizes.familyHouse} m² Einfamilienhaus.
            </span>
          </div>
        </SectionM>
      </div>
    );
  };

  const renderServicePromise = () => (
    <SectionM classname="heyg-container--filled">
      <div className="flex flex-col sm:flex-row justify-center items-center sm:items-start gap-12 py-8 px-4 lg:px-8">
        <div key="Service" className="flex-1 max-w-[447px] flex flex-col gap-4">
          <div className="flex justify-center items-center gap-2">
            <img
              src="/images/icons/shield-check.svg"
              width="28px"
              height="28px"
              alt="heygrün service shield icon"
            />
            <h3>Unser Service</h3>
          </div>
          <p className="text-center">
            Konfigurator ausfüllen und Orientierungspreis erhalten - 100%
            kostenlos.
          </p>
        </div>
        <div
          key="Zufriedenheitsgarantie"
          className="flex-1 max-w-[447px] flex flex-col gap-4"
        >
          <div className="flex justify-center items-center gap-2">
            <img
              src="/images/icons/happy-face.svg"
              width="28px"
              height="28px"
              alt="heygrün happy face icon"
            />
            <h3>Zufriedenheitsgarantie</h3>
          </div>
          <p className="text-center">
            heygrün findet die passende Lösung mit dir - für dich.
          </p>
        </div>
      </div>
    </SectionM>
  );

  return (
    <PageLayout1>
      <div>
        <div className="flex flex-col items-center justify-center text-center mt-large">
          <h1>Dachbegrünung jetzt starten!</h1>
          <h2 className="mb-xlarge">
            Klicke dich in 3 einfachen Schritten zum Orientierungspreis.
          </h2>
        </div>
        {renderRoofSizeForm()}
        {renderServicePromise()}
      </div>
    </PageLayout1>
  );
}

export default PageConfiguratorLanding;
