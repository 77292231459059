import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  INTERN_NO_MATCH, INTERN_CONFIGURATOR_PLANER, INTERN_CONFIGURATOR_START, INTERN_ACCOUNT_LOGIN, INTERN_ACCOUNT_BASE, INTERN_ACCOUNT_UPLOAD_PHOTO, INTERN_ACCOUNT_UPLOAD_DOCUMENT
} from './@global/routes';
import HEYGLoader from './@ui-common/HEYGLoader/HEYGLoader';

import PageConfigurator from './domains/Configurator/components/page-configurator/page-configurator';
import PageNotFound from './domains/Application/components/page-not-found/PageNotFound';
import PageConfiguratorLanding from './domains/Configurator/components/page-configurator-landing';
import PageLeadAppImageUpload from './domains/Lead App/components/page-lead-app-forms/page-lead-app-image-upload.js';
import PageLeadAppOverview from './domains/Lead App/components/page-lead-app-overview.js';
import PrivateRoutes from './@global/private-routes';
import { AuthProvider } from './@global/context/auth-context';
import PageLogin from './domains/Application/components/page-login/page-login';
import PageLayout2 from './@ui-common/PageLayout2/PageLayout2.js';
import PageLeadAppDocumentUpload from './domains/Lead App/components/page-lead-app-forms/page-lead-app-document-upload.js';
import { StatusProvider } from './@global/context/status-context.js';
import { LoadingProvider } from './@global/context/loading-context.js';

function App() {
  const initialProcessState = { isPending: false, submitted: false, hasError: false };
  const [process, setProcess] = useState(initialProcessState);

  const toggleProcessState = (state) => {
    setProcess(state);
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div
      className="relative w-full min-h-[100vh] grid grid-cols-4 [&>*]:col-span-4 grid-rows-layout gap-0 bg-white md:px-0"
    >
      <div />
      <BrowserRouter>
        <LoadingProvider>
          <StatusProvider>
            <AuthProvider>
              <Routes>
                <Route path={INTERN_NO_MATCH} element={<PageNotFound />} />
                <Route path={INTERN_ACCOUNT_LOGIN + '/:id'} element={<PageLogin />}></Route>
                <Route path={INTERN_CONFIGURATOR_START} element={<PageConfiguratorLanding />} />
                <Route
                  path={INTERN_CONFIGURATOR_PLANER}
                  element={<PageConfigurator process={process} callback={toggleProcessState} />}
                />
                {/** @deprecated route
                <Route path={INTERN_CONFIGURATOR_CONFIRMATION} element={<ConfiguratorConfirmation />} />
                */}
                <Route element={<PrivateRoutes />}>
                  <Route
                    path={INTERN_ACCOUNT_BASE}
                    element={<PageLayout2><PageLeadAppOverview /></PageLayout2>}
                    exact
                  />
                  <Route
                    path={`${INTERN_ACCOUNT_BASE}${INTERN_ACCOUNT_UPLOAD_PHOTO}`}
                    element={<PageLayout2><PageLeadAppImageUpload /></PageLayout2>}
                    exact
                  />
                  <Route
                    path={`${INTERN_ACCOUNT_BASE}${INTERN_ACCOUNT_UPLOAD_DOCUMENT}`}
                    element={<PageLayout2><PageLeadAppDocumentUpload /></PageLayout2>}
                    exact
                  />
                </Route>
              </Routes>
            </AuthProvider>
          </StatusProvider>
        </LoadingProvider>
      </BrowserRouter>
      <div />
      {process.isPending && <HEYGLoader info='Deine Anfrage wird übermittelt. Gleich kannst du deinen Beratungstermin buchen ...' />}
    </div>
  );
}

export default App;
