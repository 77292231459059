import React, { useEffect, useState } from 'react';
import { decodeFromUrlQueryString } from '../../../../../@global/encoder';
import { EXTERN_DATA_PRIVACY } from '../../../../../@global/routes';
import HEYGText from '../../../../../@ui-common/HEYGText/HEYGText';
import HEYGToggle from '../../../../../@ui-common/HEYGToggle/HEYGToggle';
import SectionM from '../../../../../@ui-common/SectionM/SectionM';
import submitFormElements from '../../../data/new-lead-submit-form';

function ConfiguratorSubmit() {
  const [postalCode, setPostalCode] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const decodedUrlParams = decodeFromUrlQueryString(queryString);
    setPostalCode(decodedUrlParams.get('postalcode'));
  }, []);

  const renderDataProtectionLinkText = () => (
    // eslint-disable-next-line react/jsx-filename-extension
    <span className='heyg-text-base'>
      Ich habe die
      {' '}
      <a href={EXTERN_DATA_PRIVACY} target='_blank' rel="noreferrer" className="heyg-btn-tertiary">Datenschutzhinweise</a>
      {' '}
      zur Kenntnis genommen
      <span className="text-red-500">*</span>
    </span>
  );

  const renderSubmitForm = () => (
    <form className="[&>*]:mb-8">
      {
        submitFormElements.map((entry) => (
          <div key={entry.label}>
            <span
              className="block heyg-text-s text-[#959fa5] mb-2"
            >
              {`Bitte fülle aus: ${entry.label}`}
            </span>
            <div className="grid grid-cols-1 md:grid-cols-4 [&>*]:col-span-2 gap-4">
              {entry.inputs.map((input) => (
                <div
                  key={input.id}
                  className={input.id === 'submit-address-1' ? 'md:first:col-span-4' : undefined}
                >
                  <HEYGText
                    id={input.id}
                    type={input.type}
                    required={input.required}
                    name={input.name}
                    label={input.label}
                    placeholder={input.placeholder}
                    regValidation={input.regValidation}
                    errorMessage={input.errorMessage}
                    defaultValue={input.name === 'lead_plz' ? postalCode : null}
                  />
                </div>
              ))}
            </div>
          </div>
        ))
      }
      <div>
        <span
          className="block heyg-text-s text-[#959fa5] mb-3.5"
        >
          Optional: Anmerkung
        </span>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <textarea
            id="submit-comment-1"
            name="lead_comment"
            className="col-span-4 hover:bg-neutral-200 border-b border-neutral-500 bg-neutral-100 heyg-text-base text-neutral-900 focus:outline-none focus:border-HEYG-secondary placeholder-neutral-400 px-2 py-2"
            placeholder="z.B.: Hat das Dach eine andere Adresse als dein Wohnsitz? Hast du Fragen? Lass es uns wissen!"
            rows="4"
          />
        </div>
      </div>
      <div>
        <HEYGToggle
          id="submit-legal"
          required
          name="lead_accept_legal"
          content={renderDataProtectionLinkText()}
        />
      </div>
    </form>
  );

  return (
    <div>
      <div className='mb-small sm:mb-xlarge'>
        <div className='p-4 corporate-gradient sm:p-0 sm:bg-none sm:mb-[0.5rem]'>
          <p className='heyg-title'>Wo befindet sich dein Dach?</p>
        </div>
        <div className='p-4 bg-[#1a1a1a] sm:p-0 sm:bg-transparent'>
          <p className='text-4 sm:text-5 text-HEYG-white sm:text-[#1a1a1a]'>
            <strong className='inline sm:hidden'>Tipp:{' '}</strong>
            Wir benötigen diese Informationen, um dir dein personalisiertes und unverbindliches Angebot zukommen zu lassen.
          </p>
        </div>
      </div>
      <SectionM>
        {renderSubmitForm()}
      </SectionM>
    </div>
  );
}

export default ConfiguratorSubmit;
